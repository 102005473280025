.shortcut-icons {
  position: fixed;
  top: 90px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 500;
  opacity: 0.9;
  &:hover {
    opacity: 1;
    transition: opacity 0.1s;
  }
  @media (max-width: 820px) {
    top: 80px;
    right: 10px;
  }
  .icon {
    display: flex;
    align-items: center;
    margin: 3px 0;
    a {
      border-radius: 50%;
      display: flex;
      height: 60px;
      width: 60px;
      justify-content: center;
      align-items: center;
      border: 2px solid white;
      @media (max-width: 820px) {
        height: 40px;
        width: 40px;
      }
      i {
        color: white;
        font-size: 22px;
        @media (max-width: 820px) {
          font-size: 14px;
        }
      }
    }
    p {
      display: none;
      margin: 0 4px 0 0;
      padding: 2px 5px;
      background-color: rgba($color: #000000, $alpha: 0.6);
      color: white;
      @media (max-width: 820px) {
        font-size: 14px;
      }
    }
    &:hover {
      p {
        display: block;
      }
      a {
        text-decoration: none;
        transition: background-color 0.3s;
      }
    }
  }
  .facebook-icon {
    a {
      background-color: #3d2cb9;
    }
    &:hover {
      a {
        background-color: #3d2cb0;
      }
    }
  }
  .contact-icon {
    a {
      background-color: #fbd773;
    }
    &:hover {
      a {
        background-color: #fbd764;
      }
    }
  }
  .ecoledirecte-icon {
    a {
      background-color: #f5f5f5;
      img {
        height: 25px;
        object-fit: cover;
      }
    }
    &:hover {
      a {
        background-color: #f5f5f0;
      }
    }
  }
}
