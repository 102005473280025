.liste-actualites {
  margin: 50px 0;
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 10px;
    .carte-actualite {
      width: 275px;
      height: 350px;
      border-radius: 20px;
      overflow: hidden;
      background-color: #f2f2f2;
      margin: 10px;
    }
  }
}
