.carte-actualite-link {
  color: black;
  text-decoration: none;
  height: inherit;
  display: flex;
  flex-direction: column;
  .actualite-default-picture {
    height: 150px;
    background-position: center;
    background-size: cover;
  }
  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    text-overflow: ellipsis;
    p {
      margin: 0;
      font-size: 14px;
    }
    .content-top {
      max-height: 110px;
      overflow: hidden;
      h3 {
        font-size: 16px;
        max-height: inherit;
      }
      .actualite-resume {
        p {
          overflow: hidden;
          max-height: 90px;
          text-overflow: ellipsis;
        }
      }
    }
    .content-bottom {
      display: flex;
      justify-content: space-between;
      i {
        margin-right: 10px;
        font-size: 22px;
        color: #00c1ef;
      }
      p {
        color: #a7a7a7;
        margin-left: 10px;
      }
    }
  }
}
.carte-actualite-link:hover {
  text-decoration: none;
  color: inherit;
  transform: scale(1.05);
  transition: transform 0.4s;
}
