.links-area {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  .link-container {
    justify-content: center;
    flex-wrap: wrap;
    .link {
      i {
        font-size: 72px;
      }
      a {
        color: black;
        font-size: 14px;
        font-weight: normal;
        align-self: flex-start;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          margin-right: 5px;
          font-size: 10px;
        }
      }
      p {
        font-size: 20px;
      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: white;
      border-radius: 20px;
      width: 250px;
      height: 250px;
      margin: 10px;
      align-items: center;
      padding: 20px;
      position: relative;
      overflow: hidden;
    }
    .link:hover {
      .overlay {
        display: block;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.9;
        color: white;
        font-weight: bold;
        font-size: 18px;
        &:hover {
          text-decoration: none;
        }
        transition: opacity 0.4s;
      }
    }
  }
}
