html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
}
#body {
  min-height: calc(100vh - 400px);
  overflow-x: hidden;
}
section {
  padding-top: 50px;
  padding-bottom: 30px;
}
.page-link-button {
  color: white !important;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 25px;
  border-radius: 20px;
  margin-top: 20px;
  min-width: 210px;
  text-align: center;
}
