.text-image-modular {
  .container {
    div {
      margin: 20px;
      @media (max-width: 920px) {
        margin: 5px;
      }
    }
    .text {
      p {
        text-align: justify;
      }
      @media (max-width: 920px) {
        order: 1 !important;
      }
    }
    .image {
      a {
        img {
          border-radius: 20px;
          height: 325px;
          width: 425px;
          object-fit: cover;
          @media (max-width: 992px) {
            width: 300px;
            height: 200px;
          }
          @media (max-width: 415px) {
            width: 275px;
            height: 150px;
          }
        }
      }
      @media (max-width: 1000px) {
        margin-top: 30px;
      }
      @media (max-width: 920px) {
        order: 2 !important;
      }
    }
    align-items: center;
    @media (max-width: 1000px) {
      align-items: flex-start;
    }
    @media (max-width: 920px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .files {
    margin-top: 40px;
    .file {
      display: flex;
      align-items: center;
      i {
        margin-right: 10px;
        font-size: 18px;
      }
      a {
        color: black;
      }
    }
  }
}
