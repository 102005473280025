.presentation-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  .top-actualite-container {
    width: 100%;
    border-radius: 20px;
    margin: 20px 0 60px 0;
    overflow: hidden;
    @media (max-width: 768px) {
      border-radius: 0px;
    }
  }
  h2 {
    text-align: center;
    margin-bottom: 5px;
  }
  h2 + p {
    margin-bottom: 20px;
  }
  p {
    text-align: center;
    @media (max-width: 768px) {
      margin: 0 20px;
    }
  }
}
