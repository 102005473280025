.liste-albums {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .card {
      width: 300px;
      height: 250px;
      overflow: hidden;
      position: relative;
      margin: 10px;
      .card-img-top {
        height: 150px;
        object-fit: cover;
      }
      .card-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        opacity: 0;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
    .card:hover {
      .card-btn {
        opacity: 0.8;
        transition: opacity 0.4s;
      }
    }
    @media (max-width: 992px) {
      justify-content: center;
    }
  }
}
