#home-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  h2 {
    color: #112842;
    text-align: center;
    margin-bottom: 30px;
    border-bottom: 6px solid #00c1ef;
  }
  .gallery-link {
    background-color: #00c1ef;
    border: 2px solid #00c1ef;
  }
  .gallery-link:hover {
    color: #00c1ef !important;
    text-decoration: none;
    background-color: white;
    transition: all 0.3s;
  }
  .photos-displayer {
    display: grid;
    gap: 10px;
    height: 500px;
    margin: 30px 0;
    align-self: center;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(5, 1fr);
    @media (max-width: 768px) {
      height: auto;
    }
    .photo {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
    }
    :nth-child(1) {
      grid-column: 1;
      grid-row: 1 / 6;
    }
    :nth-child(2) {
      grid-column: 2;
      grid-row: 1 / 4;
    }
    :nth-child(3) {
      grid-column: 2;
      grid-row: 4 / 6;
    }
    :nth-child(4) {
      grid-column: 3;
      grid-row: 1 / 3;
    }
    :nth-child(5) {
      grid-column: 3;
      grid-row: 3 / 6;
    }
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .photo {
        width: 300px;
        height: 250px;
      }
    }
  }
}
