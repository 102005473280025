.website-footer {
  background-color: #c49b9b;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  a {
    color: white;
    cursor: pointer;
  }
  .top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .website-logo {
      width: 200px;
      display: flex;
      align-items: center;
    }
    .contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-top: 0;
        margin-bottom: 2px;
        font-size: 18px;
      }
    }
    .facebook-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 16px;
        margin: 0;
      }
      i {
        font-size: 108px;
        color: #3d2cb9;
      }
    }
    .website-logo,
    .facebook-link,
    .contact {
      margin-bottom: 20px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
  }
}
