.text-modular {
  h3 {
    margin-bottom: 40px;
  }
  p {
    text-align: justify;
  }
  .files {
    margin-top: 40px;
    .file {
      display: flex;
      align-items: center;
      i {
        margin-right: 10px;
        font-size: 18px;
      }
      a {
        color: black;
      }
    }
  }
}
