.list-modular {
  h3 {
    margin-bottom: 40px;
  }
  .element {
    display: flex;
    i {
      margin-right: 20px;
      font-size: 22px;
      font-weight: bold;
      padding-top: 2px;
    }
    p:nth-child(1) {
      font-weight: bold;
      margin-bottom: 0;
    }
    .description {
      text-align: justify;
    }
  }
}
