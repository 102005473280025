.album-photos {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btn {
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 30px;
    text-align: center;
  }
  .liste-photos {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    a {
      img {
        width: 300px;
        height: 275px;
        object-fit: cover;
        margin: 5px;
        @media (max-width: 992px) {
          width: 275px;
          height: 250px;
        }
      }
    }
    @media (max-width: 992px) {
      justify-content: center;
      width: 100%;
    }
  }
  @media (max-width: 992px) {
    justify-content: center;
    width: 100%;
    padding: 10px;
  }
}
