#home-actualites {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: #112842;
    text-align: center;
    border-bottom: 6px solid #f58143;
  }
  .dernieres-actualites {
    width: 100%;
  }
  .plus-actualites-link {
    background-color: #ff7746;
    border: 2px solid #ff7746;
  }
  .plus-actualites-link:hover {
    color: #ff7746 !important;
    text-decoration: none;
    background-color: white;
    transition: all 0.3s;
  }
}
