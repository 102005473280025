#website-navbar {
  background-color: #fff;
  height: 65px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    margin-right: 20px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .navbar-brand {
    padding: 0;
    margin-right: 0.5rem;
    .website-logo {
      width: 130px;
      padding-left: 20px;
    }
  }
  .navbar-collapse {
    height: 100%;
    flex-grow: 0;
    .navbar-nav {
      .mega-dropdown.active,
      .mega-dropdown.active:hover {
        > a {
          color: #0070b8;
        }
      }
      .mega-dropdown {
        position: static !important;
        .dropdown-menu {
          width: 100%;
          min-width: 300px;
          background-color: #f2f2f2;
          @media (max-width: 1101px) {
            display: block;
            padding-left: 5px;
          }
        }
        .dropdown-menu.show {
          display: flex;
          justify-content: space-around;
          align-items: flex-start;
        }
        a {
          text-decoration: none !important;
          color: #797979;
          font-weight: 500;
        }
        > a:hover,
        a:active,
        a:focus {
          text-decoration: none !important;
          color: #0070b8 !important;
        }
        p {
          margin: 40px 0 0 0;
          font-weight: bold;
          font-size: 18px;
        }
        .category-img {
          width: 300px;
          height: 100px;
          object-fit: cover;
          border-radius: 10px;
          margin: 10px 0 20px 0;
        }
        .mega-dropdown-menu {
          top: 63px;
          a:hover,
          a:active,
          a:focus {
            color: #0070b8;
          }
          .ed-link {
            margin-top: 70px;
            img {
              object-fit: cover;
              @media (max-width: 1101px) {
                margin-top: 10px;
              }
            }
          }
          .submenu {
            list-style: none;
            display: flex;
            flex-direction: column;
            padding: 0;
            margin-bottom: 30px;
            li {
              margin: 3px 0;
            }
            @media (max-width: 1101px) {
              margin: 0;
              li {
                margin: 2px 0;
              }
            }
          }
        }
        @media (max-width: 1101px) {
          .category-img {
            display: none;
          }
          p {
            margin: 10px 0 3px 0;
            font-size: 14px;
          }
          > a {
            pointer-events: none;
            color: #0070b8;
          }
        }
        &:first-child {
          > a {
            pointer-events: initial;
          }
        }
      }
      @media (min-width: 1101px) {
        height: 100%;
        .dropdown {
          height: 100%;
          a {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 8px;
            font-size: 18px;
          }
        }
        .dropdown:last-child {
          display: flex;
          align-items: center;
          > a {
            height: min-content;
            border-radius: 10px;
            background-color: #fbd773;
            padding: 10px 10px;
          }
        }
        .dropdown.active {
          border-bottom: 6px solid #0070b8;
        }
        .dropdown.active:last-child {
          border-bottom: none;
        }
      }
      @media (max-width: 1101px) {
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        background-color: #f2f2f2;
        padding: 10px 20px;
        li {
          margin: 2px 0;
        }
      }
    }
    .nav {
      @media (max-width: 1101px) {
        flex-wrap: nowrap;
      }
    }
  }
}

@media (min-width: 1101px) {
  .navbar-expand-custom {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-custom .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-custom .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-custom .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-custom .navbar-collapse {
    display: flex;
    flex-basis: auto;
  }
  .navbar-expand-custom .navbar-toggler {
    display: none;
  }
}
