.contact-container {
  .introduction {
    margin: 50px 0;
    p {
      text-align: center;
    }
  }
  .contact-form {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
  .communication-tools {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 50px 0;
    background-color: rgba($color: #fbd773, $alpha: 0.1);
    border-radius: 5px;
    .tool {
      display: flex;
      align-items: center;
      margin: 20px;
      color: #3f48cc;
      i {
        margin-right: 20px;
        font-size: 32px;
      }
      a,
      p {
        margin: 0;
        color: #3f48cc;
      }
    }
  }
  #map {
    height: 500px;
  }
}
