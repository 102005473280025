.actualite-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    margin: 40px 0;
  }
  .content {
    padding: 50px;
    display: flex;
    .actualite-content {
      text-align: justify;
      margin-right: 60px;
      p:first-child {
        font-weight: bold;
      }
    }
    img {
      height: 300px;
      width: 350px;
      border-radius: 20px;
      object-fit: cover;
      -webkit-box-shadow: 11px 11px 50px -23px #000000;
      box-shadow: 11px 11px 50px -23px #000000;
    }
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
      img {
        height: 300px;
        width: 300px;
        order: 1;
        margin-bottom: 30px;
      }
      .actualite-content {
        order: 2;
        margin: 0;
      }
    }
  }
  .files {
    width: inherit;
    h4 {
      color: #0070b8;
      margin-bottom: 20px;
    }
    .file {
      width: inherit;
      display: flex;
      align-items: center;
      > i {
        font-size: 28px;
        color: #0070b8;
        margin-right: 10px;
      }
      a {
        margin: 0;
        color: #0070b8;
      }
    }
  }
}
