.top-actualite.one-actualite {
  display: inline-block;
}
.top-actualite {
  background-size: cover;
  position: relative;
  height: 250px;
  width: 100%;
  .banner {
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba($color: #f58143, $alpha: 0.8);
    color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    h3 {
      max-height: 100px;
      overflow: hidden;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      position: absolute;
      bottom: 20px;
      right: 50px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
      @media (max-width: 468px) {
        right: 10px;
        margin-bottom: 25px;
      }
      i {
        margin-left: 8px;
        font-size: 26px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
}
.top-actualite:hover {
  .banner {
    width: 100%;
    transition: width 0.5s ease-in;
  }
}
.carousel {
  height: 100%;
}
.carousel-inner {
  height: 100%;
}
