.pages-header {
  height: 300px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba($color: #829aff, $alpha: 1);
  background-blend-mode: multiply;
  overflow-x: hidden;
  h1,
  h2 {
    color: white;
    margin: 0 20px 20px;
    text-align: center;
  }
  h1 {
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
  h2 {
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
  @media (max-width: 768px) {
    height: 250px;
  }
}
