.home-header {
  .header-banner {
    height: 100vh;
    min-height: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-color: rgba($color: #829aff, $alpha: 0.9);
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: center;
    h1,
    h2 {
      margin: 0;
      color: white;
    }
    h1 {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 58px;
      .college-name-bottom {
        padding: 5px 10px;
        font-weight: normal;
      }
      @media (max-width: 720px) {
        font-size: 42px;
      }
      @media (max-width: 500px) {
        font-size: 28px;
      }
    }
    p {
      color: #fff;
      font-size: 58px;
      font-weight: normal;
      @media (max-width: 720px) {
        font-size: 42px;
      }
      @media (max-width: 500px) {
        font-size: 28px;
      }
    }
    .separator {
      width: 200px;
      height: 8px;
      background-color: #fbd773;
      border-radius: 5px;
      margin: 10px 0;
    }
    h2 {
      color: #fff;
      padding: 5px 10px;
      font-size: 42px;
      text-align: center;
      @media (max-width: 720px) {
        font-size: 28px;
      }
      @media (max-width: 500px) {
        font-size: 22px;
      }
    }
    .more-informations-link {
      background-color: #fbd773;
      border-radius: 50px;
      padding: 15px;
      font-weight: bold;
      text-decoration: none;
      color: white;
      font-size: 28px;
      margin-top: 50px;
      border: 2px solid #fbd773;
      height: 100px;
      display: flex;
      align-items: center;
      @media (max-width: 720px) {
        font-size: 18px;
        height: 70px;
      }
      @media (max-width: 500px) {
        font-size: 14px;
        height: 50px;
      }
    }
    .more-informations-link:hover {
      border: 2px solid #fbd773;
      background-color: white;
      color: #fbd773;
      transition: all 0.3s;
    }
  }
}
