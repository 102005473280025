.pdf-modular {
  .container {
    display: flex;
    flex-direction: column;
    h3 {
      margin-bottom: 40px;
    }
    object,
    embed {
      height: 500px;
      width: 100%;
      align-self: center;
    }
    p {
      align-self: center;
    }
  }
}
